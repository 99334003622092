import axios from "./client";
import { ProductActions } from "../reducers/ProductReducer";
import store from "./../store";
import { ProductListFileActions } from "../reducers/ProductListFileReducer";
import { ProductStatisticActions } from "../reducers/ProductStatisticsReducer";
const API_URL = process.env.REACT_APP_API_URL;
const API_URL2 = process.env.REACT_APP_API_STATISTICS_URL;

export const fetchProducts = async () => {
  store.dispatch({ type: ProductActions.RequestProducts, payload: {} });
  const { data: products } = await axios.get<Product[]>(`${API_URL}products`);
  store.dispatch({
    type: ProductActions.ReceiveProducts,
    payload: products,
  });
};
export const upsertProducts = async (products: Partial<Product>[]) => {
  store.dispatch({ type: ProductActions.RequestProducts, payload: {} });
  await axios
    .post<Product[] | any>(`${API_URL}products/massive`, products)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch({
          type: ProductActions.AddProducts,
          payload: res.data,
        });
      }
    }).catch((res) => {
      if (res.status === 400) {
        store.dispatch({
          type: ProductActions.UpdateErrors,
          payload: res.data,
        });
      } else if (res.status === 500) {
        store.dispatch({
          type: ProductActions.UpdateErrorMsg,
          payload: "Server error",
        });
      }
    });
};

export const updateFullCatalog = async (products: Product[], id?: string) => {
  store.dispatch({ type: ProductActions.RequestProducts, payload: {} });
  await axios
    .post<Product[]>(`${API_URL}products/massive_prices`, {
      products,
      id
    })
    .then((res) => {
      if (res.status === 200) {
        store.dispatch({
          type: ProductActions.UpdateProducts,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      store.dispatch({
        type: ProductActions.UpdateErrorMsg,
        payload: "Server error",
      });
    });
};

export const updateIsModifiable = async (id:string, isModifiable:boolean) => {
  store.dispatch({ type: ProductActions.RequestProducts, payload: {} });
  await axios
    .patch<Product[]>(`${API_URL}products/isModifiable/${id}`, {isModifiable})
    .then((res) => {
      if (res.status === 200) {
        store.dispatch({
          type: ProductActions.UpdateProduct,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      store.dispatch({
        type: ProductActions.UpdateErrorMsg,
        payload: "Server error",
      });
    });
}

export const fetchProductListFiles = async () => {
  store.dispatch({ type: ProductListFileActions.RequestFiles, payload: {} });
  try {
    const { data } = await axios.get(`${API_URL}products/listFiles`);
    store.dispatch({
      type: ProductListFileActions.ReceiveFiles,
      payload: data.Contents,
    });
  }

  catch (err) {
    console.log(err);
    store.dispatch({ type: ProductListFileActions.SetError, payload: "Error al obtener los archivos" });
    setTimeout(() => {
      store.dispatch({ type: ProductListFileActions.ClearError, payload: {} });
    }
      , 20000);
  }
};

export const uploadProductListFile = async (file: File, fileName: string) => {
  store.dispatch({ type: ProductListFileActions.UploadFile, payload: {} });
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    const uploadResponse = await axios.post(`${API_URL}products/upload`, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
    if (uploadResponse.status !== 200) {
      throw new Error();
    }
    store.dispatch({ type: ProductListFileActions.SetSuccess, payload: "Archivo subido correctamente" });
  } catch (err) {
    console.log(err);
    store.dispatch({ type: ProductListFileActions.SetError, payload: "Error al subir el archivo" });
  }
  store.dispatch({ type: ProductListFileActions.UploadFileSuccess, payload: {} });


};

export const deleteProductListFile = async (fileName: string) => {
  store.dispatch({ type: ProductListFileActions.DeleteFile, payload: {} });
  try {
    const deleteResponse = await axios.delete(`${API_URL}products/deleteFile/${fileName.split('/')[1]}`)
    if (deleteResponse.status !== 200) {
      throw new Error();
    }
    store.dispatch({ type: ProductListFileActions.SetSuccess, payload: "Archivo eliminado correctamente" });
  } catch (err) {
    console.log(err);
    store.dispatch({ type: ProductListFileActions.SetError, payload: "Error al eliminar el archivo" });
  };
  store.dispatch({ type: ProductListFileActions.DeleteFileSuccess, payload: {} });
};

export const getProductListFile = (fileName: string) => {
  const url = "https://todoferretero-bucket.s3.sa-east-1.amazonaws.com/" + encodeURIComponent(fileName);
  window.open(url, "_blank");
};

export const uploadProductListFileXlss = async (products: Array<any>) => {
  try {
    store.dispatch({ type: ProductStatisticActions.SendProductStatistics, payload: {} });
    await axios.post(`${API_URL2}products/bulkInsert`, {
      products: products,
    });
    store.dispatch({ type: ProductStatisticActions.ReceiveErrorsProductStatistics, payload: "Productos actualizados correctamente." });
  }
  catch (err) {
    store.dispatch({ type: ProductStatisticActions.UpdateErrorMsg, payload: "Error al actualizar los productos." });
    alert("Error al actualizar los productos.");
  }
  store.dispatch({ type: ProductStatisticActions.UpdateSuccess, payload: {} });
};